// @flow
// $FlowFixMe
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import Modal from './Modal';
import CopyButton from '../util/CopyButton';

import EmbedShareModalTitle from './EmbedShareModalTitle';
import * as uiActions from '../../actions/ui';

import * as analyticsActions from '../../actions/analytics';
import { selectOpenModalTypeIs, selectEmbedShareModal } from '../../selectors/modals';

import stylesShareModal from './ShareModal.css';
import RadioButton from '../util/RadioButton';
import { UI_THEME_DARK, UI_THEME_LIGHT } from '../../constants';

const messages = defineMessages({
  light: {
    id: 'embed-share-modal.theme.light',
    defaultMessage: 'light',
  },
  dark: {
    id: 'embed-share-modal.theme.dark',
    defaultMessage: 'dark',
  },
});

type MapStateToProps = {
  isOpen: boolean,
  embedCode?: string,
  entityType: string,
  trackShareButtonClick: Function,
};

type DispatchProps = {
  hideModal: Function,
};

type Props = MapStateToProps & DispatchProps;

const THEMES = [UI_THEME_LIGHT, UI_THEME_DARK];

const EmbedShareModal = ({
  isOpen,
  hideModal,
  embedCode,
  entityType,
  trackShareButtonClick,
}: Props) => {
  const embedCodeInputRef = useRef(null);
  const [themePreference, setThemePreference] = useState(UI_THEME_LIGHT);
  const [embedCodeWithThemeParam, setEmbedCodeWithThemeParam] = useState(null);

  const updateEmbedCodeWithThemeParam = useCallback(() => {
    if (!embedCode) return null;

    const srcMatch = embedCode.match(/src=['"]([^'"]+)['"]/);
    if (!srcMatch) return embedCode;

    const srcUrl = new URL(srcMatch[1]);
    srcUrl.searchParams.set('theme', themePreference);

    return embedCode.replace(srcMatch[1], srcUrl.toString());
  }, [embedCode, themePreference]);

  useEffect(() => {
    const updatedEmbedCode = updateEmbedCodeWithThemeParam();
    setEmbedCodeWithThemeParam(updatedEmbedCode);
  }, [themePreference, embedCode, updateEmbedCodeWithThemeParam]);

  const onCopyEmbedClick = () => {
    if (embedCodeWithThemeParam && embedCodeInputRef.current) {
      embedCodeInputRef.current.select();
      document.execCommand('copy');
      trackShareButtonClick('CopyEmbedCode');
    }
  };

  const selectEmbed = () => {
    embedCodeInputRef.current.select();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={hideModal}
      onClose={hideModal}
      contentLabel="embed-share-modal"
    >
      <div className={stylesShareModal.modal}>
        <h1 className={stylesShareModal.title}>
          <EmbedShareModalTitle entityType={entityType} />
        </h1>
        <div className={stylesShareModal.themeSelector}>
          <label className={stylesShareModal.label}>
            <FormattedMessage id="settings.app.theme.title" defaultMessage="Appearance:" />
          </label>
          {THEMES.map(theme => (
            <RadioButton
              key={theme}
              className="input-radio--dark"
              checked={themePreference === theme}
              id={`embed-theme-${theme}`}
              onChange={e => setThemePreference(e.target.value)}
              name="embed-theme"
              value={theme}
              description={<FormattedMessage {...messages[theme]} />}
            />
          ))}
        </div>
        {embedCodeWithThemeParam && (
          <React.Fragment>
            <label className={stylesShareModal.label}>
              <FormattedMessage id="modal.share.embed-code" defaultMessage="Embed Code:" />
            </label>
            <div className={stylesShareModal.field}>
              <button onClick={selectEmbed} className={stylesShareModal.inputLink}>
                <input
                  readOnly
                  value={embedCodeWithThemeParam}
                  ref={embedCodeInputRef}
                  className={`c-form-field__input ${stylesShareModal.input}`}
                />
              </button>
              <CopyButton onClick={onCopyEmbedClick} />
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const { embedCode, entityType, trackShareButtonClick } = selectEmbedShareModal(state);
  return {
    isOpen: selectOpenModalTypeIs(state, 'EMBED_SHARE_MODAL'),
    embedCode,
    entityType,
    trackShareButtonClick,
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
  analyticsTrack: analyticsActions.track,
};

export default connect(mapStateToProps, dispatchProps)(EmbedShareModal);
