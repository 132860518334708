import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  album: {
    id: 'modal.embed.album.title',
    defaultMessage: 'Embed Album',
  },
  playlist: {
    id: 'modal.embed.playlist.title',
    defaultMessage: 'Embed Playlist',
  },
  recording: {
    id: 'modal.embed.recording.title',
    defaultMessage: 'Embed Recording',
  },
  albums: {
    id: 'modal.embed.albums.title',
    defaultMessage: 'Embed Albums',
  },
  playlists: {
    id: 'modal.embed.playlists.title',
    defaultMessage: 'Embed Playlists',
  },
  recordings: {
    id: 'modal.embed.recordings.title',
    defaultMessage: 'Embed Recordings',
  },
  personalPlaylist: {
    id: 'modal.embed.personal-playlist.title',
    defaultMessage: 'Embed Playlist',
  },
  livestreamEvent: {
    id: 'modal.embed.livestream-event.title',
    defaultMessage: 'Embed Event',
  },
  track: {
    id: 'modal.embed.track.title',
    defaultMessage: 'Embed Track',
  },
  artist: {
    id: 'modal.embed.artist.title',
    defaultMessage: 'Embed Artist',
  },
  genre: {
    id: 'modal.embed.genre.title',
    defaultMessage: 'Embed Genre',
  },
  epoch: {
    id: 'modal.embed.epoch.title',
    defaultMessage: 'Embed Epoch',
  },
  instrument: {
    id: 'modal.embed.instrument.title',
    defaultMessage: 'Embed Instrument',
  },
});

const EmbedShareModalTitle = ({ entityType, intl }) => {
  if (messages[entityType]) {
    return intl.formatMessage(messages[entityType]);
  }
  return (
    <FormattedMessage
      id="modal.embed.collectionTitle"
      defaultMessage="Embed {entityType}"
      values={{ entityType }}
    />
  );
};

EmbedShareModalTitle.propTypes = {
  entityType: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(EmbedShareModalTitle);
