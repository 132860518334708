import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from '../../vendor/InfiniteScroll';
import CapacitorHeaderBar from '../capacitor/HeaderBar';

import LoadingIndicator from '../common/LoadingIndicator';
import CapacitorSkeletonIfLoading from '../capacitor/SkeletonIfLoading';
import List from '../util/List';

import stylesPlaylistCollection from './PlaylistCollection.css';
import stylesCollection from '../../views/Collection.css';

export default class PaginatedCollection extends Component {
  static propTypes = {
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    children: PropTypes.element,
    isLoading: PropTypes.bool.isRequired,
    styles: PropTypes.string,
    title: PropTypes.element,
    showShareModal: PropTypes.func,
  };

  render() {
    const {
      children,
      title,
      loadMore,
      hasMore,
      items,
      isLoading,
      renderItem,
      styles = stylesPlaylistCollection.list,
    } = this.props;

    return (
      <React.Fragment>
        {__CAPACITOR__ && (
          <CapacitorHeaderBar title={title} showShareModal={this.props.showShareModal} />
        )}
        {title && !__CAPACITOR__ && (
          <div className={stylesCollection.header}>
            <h1 className="fz--beta">{title}</h1>
          </div>
        )}
        {children}
        <CapacitorSkeletonIfLoading noInsets>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            scrollableSelector={'.content'}
            useWindow={false}
            loader={null}
          >
            <List className={styles} items={items} renderItem={renderItem} />
          </InfiniteScroll>
          <LoadingIndicator isLoading={isLoading} />
        </CapacitorSkeletonIfLoading>
      </React.Fragment>
    );
  }
}
