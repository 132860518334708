import { APP_SCROLLER_CLASS } from '../constants';

export function activeElementCapturesKeyEvents(event) {
  const ownAttribute = event.target.dataset.capturesKeydown;
  const parentAttribute =
    event.target.parentElement && event.target.parentElement.dataset.capturesKeydown;
  return Boolean(ownAttribute) || Boolean(parentAttribute);
}

export function activeElementIsTextField() {
  const activeElementTagName = document.activeElement.tagName.toLowerCase();
  return activeElementTagName === 'input' || activeElementTagName === 'textarea';
}

export function getKeysFromInputName(name) {
  return name.split(']').join('').split('[');
}

export function scrollToY(scrollTargetY = 0, speed = 2000, easing = 'easeOutSine') {
  // scrollTargetY: the target scrollY property of the content area
  // speed: time in pixels per second
  // easing: easing equation to use
  const scrollableArea = document.querySelector(`.${APP_SCROLLER_CLASS}`);
  const scrollY = scrollableArea.scrollTop;
  let currentTime = 0;

  // min time .1, max time .8 seconds
  const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8));

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  const easingEquations = {
    easeOutSine: function easeOutSine(pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine: function easeInOutSine(pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1);
    },
    easeInOutQuint: function easeInOutQuint(pos) {
      const p = pos / 0.5;
      if (p < 1) {
        return 0.5 * Math.pow(p, 5);
      }
      return 0.5 * (Math.pow(p - 2, 5) + 2);
    },
  };

  function tick() {
    currentTime += 1 / 60;

    const p = currentTime / time;
    const t = easingEquations[easing](p);

    if (p < 1) {
      window.requestAnimationFrame(tick);
      scrollableArea.scrollTop = scrollY + (scrollTargetY - scrollY) * t;
    } else {
      scrollableArea.scrollTop = scrollTargetY;
    }
  }

  tick();
}

export function setWindowInsets(windowInsets) {
  const insets = windowInsets ? windowInsets : { top: 0, left: 0, right: 0, bottom: 0 };
  document.documentElement.style.setProperty('--window-insets-top', `${insets.top}px`);
}

export function setViewportHeight() {
  let vh = Math.floor(window.innerHeight);
  document.documentElement.style.setProperty('--viewportHeight', `${vh}px`);
  window.addEventListener('resize', () => {
    vh = Math.floor(window.innerHeight);
    document.documentElement.style.setProperty('--viewportHeight', `${vh}px`);
  });
}

// Lifted from:
// https://github.com/eldargab/load-script/blob/master/index.js
function stdOnEnd(element, cb) {
  element.onload = () => {
    element.onerror = element.onload = null;
    cb(null, element);
  };
  element.onerror = () => {
    // this.onload = null here is necessary
    // because even IE9 works not like others
    element.onerror = element.onload = null;
    cb(new Error('Failed to load ' + element.src || element.href), element);
  };
}

export function loadStylesheet(href, cb, extraAttrs) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = href;
  Object.keys(extraAttrs).forEach(key => {
    link[key] = extraAttrs[key];
  });

  stdOnEnd(link, cb);
  document.getElementsByTagName('head')[0].appendChild(link);
}

function loadScript(src, cb, extraAttrs) {
  const script = document.createElement('script');
  script.src = src;
  Object.keys(extraAttrs).forEach(key => {
    script[key] = extraAttrs[key];
  });

  stdOnEnd(script, cb);
  document.getElementsByTagName('head')[0].appendChild(script);
}

// Lifted from:
// https://github.com/CookPete/react-player/blob/32bb251a60f1a6449bd20e6dcf4c7dbfaeab02b7/src/utils.js#L61
const requests = {};
export function getSDK(
  url,
  sdkGlobal,
  sdkReady = null,
  isLoaded = () => true,
  fetchFunc = loadScript,
  extraAttrs = {}
) {
  if (window[sdkGlobal] && isLoaded(window[sdkGlobal])) {
    return Promise.resolve(window[sdkGlobal]);
  }

  return new Promise((resolve, reject) => {
    // If we are already loading the SDK, add the resolve and reject
    // functions to the existing array of requests
    if (requests[url]) {
      requests[url].push({ resolve, reject });
      return;
    }
    requests[url] = [{ resolve, reject }];
    const onLoaded = sdk => {
      // When loaded, resolve all pending request promises
      requests[url].forEach(request => request.resolve(sdk));
    };
    if (sdkReady) {
      const previousOnReady = window[sdkReady];
      window[sdkReady] = () => {
        if (previousOnReady) previousOnReady();
        onLoaded(window[sdkGlobal]);
      };
    }
    fetchFunc(
      url,
      err => {
        if (err) {
          // Loading the SDK failed – reject all requests and
          // reset the array of requests for this SDK
          requests[url].forEach(request => request.reject(err));
          requests[url] = null;
        } else if (!sdkReady) {
          onLoaded(window[sdkGlobal]);
        }
      },
      extraAttrs
    );
  });
}
