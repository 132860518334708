// @flow
// $FlowFixMe
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Modal from './Modal';
import CopyButton from '../util/CopyButton';
import ShareIcons from '../profile/ShareIcons';

import ShareModalTitle from './ShareModalTitle';
import * as uiActions from '../../actions/ui';

import * as analyticsActions from '../../actions/analytics';
import {
  selectOpenModalTypeIs,
  selectSharedUrl,
  selectSharedUrlParams,
  selectEmbedCode,
  selectSharedEntityType,
  selectTrackingContext,
} from '../../selectors/modals';

import stylesShareModal from './ShareModal.css';
import ShareModalLinkLabel from './ShareModalLinkLabel';
import EmbedCopyButton from '../util/EmbedCopyButton';

type MapStateToProps = {
  isOpen: boolean,
  url?: string,
  urlParams?: Object,
  embedCode?: string,
  entityType: string,
  trackingContext: Object,
};

type DispatchProps = {
  hideModal: Function,
  analyticsTrack: Function,
  showModal: Function,
};

type Props = MapStateToProps & DispatchProps;

const ShareModal = ({
  isOpen,
  hideModal,
  url,
  urlParams,
  embedCode,
  entityType,
  trackingContext,
  analyticsTrack,
  showModal,
}: Props) => {
  const urlInputRef = useRef(null);

  const trackShareButtonClick = sharingDestination => {
    const trackingProps = {
      ...trackingContext,
      sharingDestination,
    };
    if (sharingDestination !== 'CopyEmbedCode') {
      trackingProps.sharedUrl = url;
    }
    analyticsTrack('Clicked Share Button', trackingProps);
  };

  const onEmbedClick = () => {
    showModal('EMBED_SHARE_MODAL', null, {
      embedCode,
      entityType,
      trackShareButtonClick,
    });
  };

  const getUrlWithParams = additionalParams => {
    if (!url) {
      return '';
    }

    const newUrl = new URL(url);
    const searchParams = new URLSearchParams(newUrl.search);
    const allParams = {
      ...urlParams,
      ...additionalParams,
    };

    if (allParams) {
      Object.keys(allParams).forEach(key => searchParams.append(key, allParams[key]));
    }

    const searchString = searchParams.toString();
    const urlString = newUrl.origin + newUrl.pathname;
    return searchString ? `${urlString}?${searchString}` : urlString;
  };

  const selectUrl = () => {
    urlInputRef.current.select();
  };

  const onCopyUrlClick = () => {
    if (urlInputRef.current) {
      selectUrl();
      document.execCommand('copy');
      trackShareButtonClick('CopyLink');
    }
  };

  const copyLinkUrl = getUrlWithParams({ utm_source: 'pcl' });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={hideModal}
      onClose={hideModal}
      contentLabel="share-modal"
    >
      <div className={stylesShareModal.modal}>
        <h1 className={stylesShareModal.title}>
          <ShareModalTitle entityType={entityType} />
        </h1>
        {url && (
          <React.Fragment>
            <label className={stylesShareModal.label}>
              <ShareModalLinkLabel entityType={entityType} />
            </label>
            <div className={stylesShareModal.field}>
              <button onClick={selectUrl} className={stylesShareModal.inputLink}>
                <input
                  readOnly
                  value={copyLinkUrl}
                  ref={urlInputRef}
                  className={`c-form-field__input ${stylesShareModal.input}`}
                />
              </button>
              <CopyButton onClick={onCopyUrlClick} />
            </div>
          </React.Fragment>
        )}
        <div className={stylesShareModal.options}>
          <div>
            <label className={stylesShareModal.label}>
              <FormattedMessage id="modal.share.social" defaultMessage="Share On" />
            </label>
            <ShareIcons
              trackShareButtonClick={trackShareButtonClick}
              getUrlWithParams={getUrlWithParams}
            />
          </div>
          {embedCode && (
            <div className={stylesShareModal.embed}>
              <EmbedCopyButton onClick={onEmbedClick} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isOpen: selectOpenModalTypeIs(state, 'SHARE_MODAL'),
    url: selectSharedUrl(state),
    urlParams: selectSharedUrlParams(state),
    embedCode: selectEmbedCode(state),
    entityType: selectSharedEntityType(state),
    trackingContext: selectTrackingContext(state),
  };
}

const dispatchProps: DispatchProps = {
  hideModal: uiActions.hideModal,
  analyticsTrack: analyticsActions.track,
  showModal: uiActions.showModal,
};

export default connect(mapStateToProps, dispatchProps)(ShareModal);
