// @flow
import React from 'react';

type Props = {
  noInsets?: boolean,
};

const Skeleton = ({ noInsets }: Props) => (
  <div className={`global-skeleton ${noInsets ? 'no-insets' : 'with-insets'}`}>
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '100%' }} />
    <div
      className="global-skeleton-item"
      style={{ marginBottom: '2rem', height: '2rem', width: '80%' }}
    />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '60%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '80%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '40%' }} />
    <div className="global-skeleton-item" style={{ marginBottom: '2rem', width: '50%' }} />
  </div>
);

export default Skeleton;
