// @flow
declare var __ELECTRON__: boolean;

import { TRACK_URL_EXPIRY_MILLISECONDS as expiry } from '../constants';
import {
  selectWebappClientVersion,
  selectElectronClientVersion,
  selectDeviceId,
  selectEncryptionEnabled,
  selectUserAgentInfo,
  selectIsChrome,
} from '../selectors/client';
import { getTrackUrlResolver } from '../client/playback-support';
import { loaded, notExpired } from '../middleware/apiCacheValidators';
import { selectAccessToken } from '../selectors/auth';
import { selectSonosIsConnected } from '../selectors/sonos';

import type { AudioQuality } from './client';

export const getCompoundId = (trackId: string, quality: AudioQuality) =>
  [trackId, quality].join('-');
const validateCached = cached => loaded(cached) && notExpired(cached);
const selectCached = (state, compoundId) => state.maps.trackUrls[compoundId];
const selectTrackUrlResolver = state =>
  getTrackUrlResolver(selectUserAgentInfo(state), selectEncryptionEnabled(state));

import type { ThunkAction } from './types';
export type FetchTrackUrlAction = {
  type: 'FETCH_TRACK_URL',
  phase: 'REQUEST' | 'SUCCESS' | 'FAILURE',
  trackId: string,
  quality: number,
  compoundId: string,
  requestedAt?: number,
  trackUrl?: string,
  loadedAt?: number,
  expireAt?: number,
};

export type InvalidateTrackUrlAction = {
  type: 'INVALIDATE_TRACK_URL',
  compoundId: string,
};

export type TrackUrlAction = FetchTrackUrlAction | InvalidateTrackUrlAction;

// We remember promises that are pending so we don't redo them.
// When a promise that is in-progress gets called again (by user action for example)
// we just return the same one instead of a newly generated one.
const promiseCache = {};

export function loadTrackUrl(trackId: string, quality: number): ThunkAction {
  const compoundId = getCompoundId(trackId, quality);
  return async (dispatch, getState) => {
    const state = getState();
    const rest = { trackId, quality, compoundId };

    if (promiseCache[compoundId]) {
      return promiseCache[compoundId];
    }

    const cached = selectCached(state, compoundId);
    const cacheIsValid = validateCached(cached);
    if (cacheIsValid) {
      return cached.url;
    }

    dispatch({
      type: 'FETCH_TRACK_URL',
      phase: 'REQUEST',
      requestedAt: Date.now(),
      ...rest,
    });

    try {
      const trackResolver = selectTrackUrlResolver(state);
      const accessToken = selectAccessToken(state);
      const clientVersion = (
        __ELECTRON__ ? selectElectronClientVersion : selectWebappClientVersion
      )(state);
      const deviceId = selectDeviceId(state);
      const sonosIsConnected = selectSonosIsConnected(state);
      const isChrome = selectIsChrome(state);
      const promise = trackResolver(
        trackId,
        quality,
        accessToken,
        clientVersion,
        deviceId,
        sonosIsConnected,
        !isChrome
      );
      promiseCache[compoundId] = promise;

      const trackUrl = await promise;
      delete promiseCache[compoundId];

      const loadedAt = Date.now();
      const expireAt = expiry < 0 ? -expiry : loadedAt + expiry;

      dispatch({
        type: 'FETCH_TRACK_URL',
        phase: 'SUCCESS',
        trackUrl,
        loadedAt,
        expireAt,
        ...rest,
      });

      return trackUrl;
    } catch (error) {
      dispatch({
        type: 'FETCH_TRACK_URL',
        phase: 'FAILURE',
        ...rest,
      });
      delete promiseCache[compoundId];

      throw error;
    }
  };
}

export function invalidateTrackUrl(
  trackId: string,
  quality: AudioQuality
): InvalidateTrackUrlAction {
  const compoundId = getCompoundId(trackId, quality);

  if (promiseCache[compoundId]) {
    delete promiseCache[compoundId];
  }

  return {
    type: 'INVALIDATE_TRACK_URL',
    compoundId,
  };
}

export function getStateResolver(dispatch: Dispatch) {
  return (trackId: string, quality: number) => dispatch(loadTrackUrl(trackId, quality));
}
