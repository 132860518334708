// @flow
import { last } from 'lodash';
import { isSupported as hlsIsSupported } from 'hls.js';
import { showModal } from '../actions/ui';
import { selectAudioQualityPreference } from '../selectors/user';
import { selectSupportsLossless } from '../selectors/client';
import { PLAYBACK_NOT_SUPPORTED } from '../lib/notifications';
import { AUDIO_QUALITY_HIGH, AUDIO_QUALITY_LOSSLESS, AUDIO_QUALITY_PREVIEW } from '../constants';
import { m3u8Resolver, mp3Resolver } from '../client/trackUrlResolver';
import AudioTagPlayer from './players/Player';
import HlsPlayer from './players/HlsPlayer';
import AdsPlayer from './players/AdsPlayer';

import type { UserAgentInfo } from '../actions/client';
import type { Dispatch } from '../actions/types';
import { dismissNotification } from '../actions/notifications';
import { selectSetQualityIsAllowed, selectFeatureFlag } from '../selectors/features';

type StateResolver = (trackId: string, quality: number) => any;

function clientSupportsServiceWorkers() {
  // NOTE: Service workers are disabled in Firefox Private Windows
  return 'serviceWorker' in navigator;
}

export function clientRespectsServiceWorkerRangeHeaders(userAgentInfo: UserAgentInfo): boolean {
  const { isChrome, isDesktop } = userAgentInfo;
  return isDesktop && isChrome;
}

export function clientSupportsNativeAAC(): boolean {
  const aacType = 'application/vnd.apple.mpegURL';
  const canPlayType = document.createElement('video').canPlayType(aacType);

  return canPlayType === 'probably' || canPlayType === 'maybe';
}

export function clientSupportsServiceWorkerEncryption(userAgentInfo: UserAgentInfo): boolean {
  return clientSupportsServiceWorkers() && clientRespectsServiceWorkerRangeHeaders(userAgentInfo);
}

function clientSupportsEncryptedPlayback(userAgentInfo: UserAgentInfo): boolean {
  return (
    clientSupportsServiceWorkerEncryption(userAgentInfo) ||
    clientSupportsNativeAAC() ||
    hlsIsSupported()
  );
}

export function clientSupportsPlayback(
  userAgentInfo: UserAgentInfo,
  encryptionEnabled: boolean
): boolean {
  if (encryptionEnabled) {
    return clientSupportsEncryptedPlayback(userAgentInfo);
  }

  return true;
}

function clientSupportsUnencryptedLossless(): boolean {
  if (!window.Audio) {
    return false;
  }

  const canPlayType = new window.Audio().canPlayType('audio/flac');
  return canPlayType === 'probably' || canPlayType === 'maybe';
}

function clientSupportsEncryptedLossless(userAgentInfo) {
  return (
    clientRespectsServiceWorkerRangeHeaders(userAgentInfo) &&
    clientSupportsServiceWorkerEncryption(userAgentInfo)
  );
}

export function clientSupportsLossless(
  userAgentInfo: UserAgentInfo,
  encryptionEnabled: boolean
): boolean {
  if (encryptionEnabled) {
    return clientSupportsEncryptedLossless(userAgentInfo);
  }

  return clientSupportsUnencryptedLossless();
}

function clientSupportsEncryptedMP3(userAgentInfo: UserAgentInfo): boolean {
  return clientSupportsServiceWorkers() && clientRespectsServiceWorkerRangeHeaders(userAgentInfo);
}

export function clientSupportsMP3(
  userAgentInfo: UserAgentInfo,
  encryptionEnabled: boolean
): boolean {
  if (encryptionEnabled) {
    return clientSupportsEncryptedMP3(userAgentInfo);
  }

  return true;
}

export function clientSupportsPolyfilledAAC() {
  return hlsIsSupported();
}

export function clientSupportsAAC(): boolean {
  return clientSupportsNativeAAC() || clientSupportsPolyfilledAAC();
}

export function getPlaybackNotSupportedNotification(dispatch: Dispatch) {
  return {
    ...PLAYBACK_NOT_SUPPORTED,
    learnMoreAction: notificationId => {
      dispatch(showModal('PLAYBACK_SUPPORT_MODAL'));
      dispatch(dismissNotification(notificationId));
    },
  };
}

export function getHighestAllowedQuality(state: Object): number {
  const quality = selectAudioQualityPreference(state);
  const isAllowed = selectSetQualityIsAllowed(state, quality);
  if (isAllowed) {
    // Specifically for ff users with flac preference saved
    if (quality === AUDIO_QUALITY_LOSSLESS && !selectSupportsLossless(state)) {
      return AUDIO_QUALITY_HIGH;
    }

    return quality;
  }

  /*
    Users who signed up on mobile might have a standard premium plan with no
    lossless ([50, 70]). If 90 is stored in preferences for some reason, we
    should fall back to the highest allowed quality.
  */
  return last(selectFeatureFlag(state, 'audio_quality'));
}

export function getTrackUrlResolver(
  userAgentInfo: UserAgentInfo,
  encryptionEnabled: boolean
): Function {
  if (clientSupportsMP3(userAgentInfo, encryptionEnabled)) {
    return mp3Resolver;
  }

  if (clientSupportsAAC()) {
    return m3u8Resolver;
  }

  return (url: string) => url;
}

export function getPlayerClass(userAgentInfo: UserAgentInfo, encryptionEnabled: boolean) {
  if (clientSupportsMP3(userAgentInfo, encryptionEnabled)) {
    return AudioTagPlayer;
  }

  if (clientSupportsNativeAAC()) {
    return AudioTagPlayer;
  }

  if (clientSupportsPolyfilledAAC()) {
    return HlsPlayer;
  }

  return null;
}

export function createWindowAudioDevice() {
  return new window.Audio();
}

function createDocumentVideoDevice() {
  return document.createElement('video');
}

function getDeviceClass(PlayerClass) {
  if (PlayerClass === AudioTagPlayer) {
    return createWindowAudioDevice;
  }

  if (PlayerClass === HlsPlayer) {
    return createDocumentVideoDevice;
  }

  return null;
}

export function getPlayerInstance(
  userAgentInfo: UserAgentInfo,
  encryptionEnabled: boolean,
  stateResolver: StateResolver
) {
  if (!clientSupportsPlayback(userAgentInfo, encryptionEnabled)) {
    throw new Error('Client does not support playback!');
  }

  const PlayerClass = getPlayerClass(userAgentInfo, encryptionEnabled);
  const deviceConstructor = getDeviceClass(PlayerClass);
  if (PlayerClass) {
    return new PlayerClass(stateResolver, AUDIO_QUALITY_PREVIEW, deviceConstructor);
  }

  return null;
}

export function getAdsPlayerInstance(userAgentInfo: UserAgentInfo, urlResolver: Function) {
  return new AdsPlayer(urlResolver, createWindowAudioDevice);
}
