import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  album: {
    id: 'modal.share.album.title',
    defaultMessage: 'Share Album',
  },
  playlist: {
    id: 'modal.share.playlist.title',
    defaultMessage: 'Share Playlist',
  },
  recording: {
    id: 'modal.share.recording.title',
    defaultMessage: 'Share Recording',
  },
  albums: {
    id: 'modal.share.albums.title',
    defaultMessage: 'Share Albums',
  },
  playlists: {
    id: 'modal.share.playlists.title',
    defaultMessage: 'Share Playlists',
  },
  recordings: {
    id: 'modal.share.recordings.title',
    defaultMessage: 'Share Recordings',
  },
  personalPlaylist: {
    id: 'modal.share.personal-playlist.title',
    defaultMessage: 'Share Playlist',
  },
  livestreamEvent: {
    id: 'modal.share.livestream-event.title',
    defaultMessage: 'Share Event',
  },
  track: {
    id: 'modal.share.track.title',
    defaultMessage: 'Share Track',
  },
  artist: {
    id: 'modal.share.artist.title',
    defaultMessage: 'Share Artist',
  },
  genre: {
    id: 'modal.share.genre.title',
    defaultMessage: 'Share Genre',
  },
  epoch: {
    id: 'modal.share.epoch.title',
    defaultMessage: 'Share Epoch',
  },
  instrument: {
    id: 'modal.share.instrument.title',
    defaultMessage: 'Share Instrument',
  },
});

const ShareModalTitle = ({ entityType, intl }) => {
  if (messages[entityType]) {
    return intl.formatMessage(messages[entityType]);
  }
  return (
    <FormattedMessage
      id="modal.share.collectionTitle"
      defaultMessage="Share {entityType}"
      values={{ entityType }}
    />
  );
};

ShareModalTitle.propTypes = {
  entityType: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(ShareModalTitle);
