import * as Sentry from '@sentry/react';
import { isSentryInstalled } from '../client/Sentry';
import { includes, omit } from 'lodash';
import { routerMiddleware } from 'react-router-redux';

import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import createApiMiddleware from '../middleware/api';
import analyticsMiddleware from '../middleware/analytics';
import connectionMiddleware from '../middleware/connection';
import authMiddleware from '../middleware/auth';
import keyboardMiddleware from '../middleware/keyboard';
import playerMiddleware from '../middleware/player';
import cookieMiddleware from '../middleware/cookies';
import stripeScaMiddleware from '../middleware/stripe-sca';
import featureMiddleware from '../middleware/feature';
import ttpMiddleware from '../middleware/ttp';
import capacitorMiddleware from '../middleware/capacitor';

import { REHYDRATE } from 'redux-persist/constants';
import createActionBuffer from 'redux-action-buffer';

import request from '../lib/request';

export default function createMiddleware(history, useHydration = true) {
  const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
    createApiMiddleware(request),
    featureMiddleware,
    __CAPACITOR__ ? null : ttpMiddleware,
    __CLIENT__ && !__CAPACITOR__ ? playerMiddleware : null, // important to position here
    authMiddleware,
  ].filter(item => item); // remove null

  if (__CAPACITOR__) {
    middleware.push(capacitorMiddleware);
  }

  if (__CLIENT__) {
    window.analytics ? middleware.push(analyticsMiddleware) : null;
    if (!__CAPACITOR__) {
      middleware.push(connectionMiddleware);
      middleware.push(stripeScaMiddleware);
    }
    middleware.push(keyboardMiddleware);
    middleware.push(cookieMiddleware);

    if (useHydration) {
      middleware.unshift(createActionBuffer(REHYDRATE)); // unshift to put it first
    }
  }

  if (__CLIENT__ && process.env.ENV !== 'production') {
    const logger = createLogger({
      collapsed: true,
      logger: console,
      predicate: (getState, action) => {
        const hiddenFromLog = [
          'PLAYER_PROGRESS',
          'AD_PLAYBACK_PROGRESS',
          'FLUSH_ANALYTICS_PROGRESS_BUFFER',
        ];
        return !includes(hiddenFromLog, action.type);
      },
    });

    middleware.push(logger);
  }

  if (isSentryInstalled()) {
    const sentryMiddleware = () => next => action => {
      try {
        return next(action);
      } catch (err) {
        Sentry.captureException(err, {
          extra: {
            action: omit(action, ['response']),
          },
        });
      }

      return null;
    };

    middleware.push(sentryMiddleware);
  }

  return middleware;
}
