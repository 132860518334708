/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux'; // eslint-disable-line

import { selectModalType } from '../../selectors/modals';

import LoginModal from './LoginModal';
import SignupModal from './SignupModal';
import ShareModal from './ShareModal';
import SubscribeModal from './SubscribeModal';
import PlaybackSupportModal from './PlaybackSupportModal';
import ShortcutsHelpModal from './ShortcutsHelpModal';
import LosslessSupportModal from './LosslessSupportModal';
import WelcomeModal from './WelcomeModal';
import ConsentManagerModal from './ConsentManagerModal';
import AddToPlaylistModal from './AddToPlaylistModal';
import EditPlaylistModal from './EditPlaylistModal';
import DeletePlaylistModal from './DeletePlaylistModal';
import PreviewsModal from './PreviewsModal';
import EmbedPreviewsModal from './EmbedPreviewsModal';
import FreeCollectionStatusModal from './FreeCollectionStatusModal';
import PremiumExperiencePreviewsModal from './PremiumExperiencePreviewsModal';
import FreeExperiencePreviewsModal from './FreeExperiencePreviewsModal';
import VoucherModal from './VoucherModal';
import VoucherSuccessModal from './VoucherSuccessModal';
import CourseResourcePreviewModal from './CourseResourcePreviewModal';
import BookletModal from './BookletModal';
import UpgradeModal from './UpgradeModal';
import FacebookPasswordConfirmationModal from './FacebookPasswordConfirmationModal';
import EmbedShareModal from './EmbedShareModal';

const MODAL_COMPONENTS = {
  LOGIN_MODAL: LoginModal,
  SIGNUP_MODAL: SignupModal,
  SHARE_MODAL: ShareModal,
  EMBED_SHARE_MODAL: EmbedShareModal,
  SUBSCRIBE_MODAL: SubscribeModal,
  UPGRADE_MODAL: UpgradeModal,
  PLAYBACK_SUPPORT_MODAL: PlaybackSupportModal,
  SHORTCUTS_HELP_MODAL: ShortcutsHelpModal,
  LOSSLESS_SUPPORT_MODAL: LosslessSupportModal,
  WELCOME_MODAL: WelcomeModal,
  CONSENT_MANAGER_MODAL: ConsentManagerModal,
  ADD_TO_PLAYLIST_MODAL: AddToPlaylistModal,
  EDIT_PLAYLIST_MODAL: EditPlaylistModal,
  DELETE_PLAYLIST_MODAL: DeletePlaylistModal,
  PREVIEWS_MODAL: PreviewsModal,
  EMBED_PREVIEWS_MODAL: EmbedPreviewsModal,
  FREE_COLLECTION_STATUS_MODAL: FreeCollectionStatusModal,
  PREMIUM_EXPERIENCE_PREVIEWS_MODAL: PremiumExperiencePreviewsModal,
  FREE_EXPERIENCE_PREVIEWS_MODAL: FreeExperiencePreviewsModal,
  VOUCHER_MODAL: VoucherModal,
  VOUCHER_SUCCESS_MODAL: VoucherSuccessModal,
  COURSE_RESOURCE_PREVIEW_MODAL: CourseResourcePreviewModal,
  BOOKLET_MODAL: BookletModal,
  FACEBOOK_PASSWORD_CONFIRMATION_MODAL: FacebookPasswordConfirmationModal,
};

export type ModalType = $Keys<typeof MODAL_COMPONENTS>;

type Props = {
  modalType: ModalType,
};

class ModalContainer extends React.PureComponent<Props> {
  render() {
    if (!this.props.modalType) {
      return null;
    }

    const SpecificModal = MODAL_COMPONENTS[this.props.modalType];

    return <SpecificModal />;
  }
}

const mapStateToProps = (state: Object): Props => {
  return {
    modalType: selectModalType(state),
  };
};

const connector: Connector<Object, Props> = connect(mapStateToProps, {});

export default connector(ModalContainer);
