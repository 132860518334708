import Pusher from 'pusher-js';
import { selectUserId } from '../selectors/user';
import { selectDeviceId } from '../selectors/client';
import { selectPlayerIsPlaying } from '../selectors/player';
import { pause } from '../actions/player';
import { addNotification } from '../actions/notifications';

import { PLAYBACK_STARTED_FROM_OTHER_DEVICE } from '../lib/notifications';

export let pusher;

export default function connectPusher({ dispatch, getState }) {
  // disconnect any pre-existing pusher connection
  if (pusher) {
    pusher.disconnect();
  }
  const state = getState();
  const deviceId = selectDeviceId(state);
  const userId = selectUserId(state);

  // connect and subscribe to channels
  pusher = new Pusher(window.__pusher_key__, { cluster: 'eu' });
  pusher.subscribe(`u-${userId}`);
  pusher.subscribe(`d-${deviceId}`);
  pusher.subscribe('public');

  // Handle IEP-1 messages.
  // Unsupported messages are silently discarded.
  pusher.bind('IEP-1', msg => {
    if (msg._type === 'StopPlaybackV1') {
      const deviceIdRegex = new RegExp(msg.target_device_id_regex);
      if (deviceId && deviceId.match(deviceIdRegex) && selectPlayerIsPlaying(getState())) {
        dispatch(addNotification(PLAYBACK_STARTED_FROM_OTHER_DEVICE));
        dispatch(pause());
      }
    }
  });
}
