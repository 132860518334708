function getGoogleTagConsent(cookiePreferences, allCookiesAllowed) {
  const consent = {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  };

  if (cookiePreferences.advertising || allCookiesAllowed) {
    consent.ad_storage = 'granted';
    consent.ad_user_data = 'granted';
    consent.ad_personalization = 'granted';
  }

  if (cookiePreferences.marketingAndAnalytics || allCookiesAllowed) {
    consent.analytics_storage = 'granted';
  }

  return consent;
}

export function updateGoogleTagConsent(cookiePreferences, allCookiesAllowed) {
  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('consent', 'update', getGoogleTagConsent(cookiePreferences, allCookiesAllowed));
}

export const googleTagScript = `
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      gtag('consent', 'default', ${JSON.stringify(getGoogleTagConsent({}, false))});
    </script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=${
      process.env.GOOGLE_TAG_IDAGIO_WEBSITE_ALL_COUNTRIES_ID
    }"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.GOOGLE_TAG_IDAGIO_WEBSITE_ALL_COUNTRIES_ID}');
    </script>
  `;
